import * as React from 'react'
import { graphql } from 'gatsby'
import IndexLayout from '../layouts'
import { get } from 'lodash'
import { SlideshowAcf } from '../models/Slideshow'
import Slideshow from '../components/Slideshow/Slideshow'
import MainContent from '../components/MainContent/MainContent'
import Helmet from 'react-helmet'

interface HeaderAcf extends SlideshowAcf {
  main_header?: {
    media_details: {
      sizes: {
        large: {
          source_url: string
          alt_text?: string
        }
      }
    }
  }
  sub_texts?: [{ sub_paragraph: string; sub_title?: string }]
  main_title: string
}
interface PageTemplateProps {
  data: {
    allWordpressPage: {
      edges: [
        {
          node: {
            acf: HeaderAcf
          }
        }
      ]
    }
  }
}

const PageTemplate: React.FC<PageTemplateProps> = ({ data }) => {
  if (!data.allWordpressPage.edges) return null

  const { slideshow, main_title, sub_texts }: HeaderAcf = get(data, 'allWordpressPage.edges[0].node.acf')

  const meta = {
    title: 'Veckobudgeten | Klimatbutiken',
    description:
      'Den veckobudget som vi tagit fram på 19 kg CO₂e per person baseras på att vi i Sverige idag har vi en klimatpåverkan från mat på i snitt 5,5 kg CO₂e per person och dag'
  }

  return (
    <IndexLayout>
      <Helmet title={meta.title}>
        <meta property="og:title" content={meta.title} />
        <meta name="og:description" content={meta.description} />
        <meta name="description" content={meta.description} />
      </Helmet>
      <MainContent title={main_title} text={sub_texts}></MainContent>
      <Slideshow slideshow={slideshow} />
    </IndexLayout>
  )
}

export default PageTemplate

export const query = graphql`
  query ($slug: String!) {
    allWordpressPage(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          acf {
            main_title
            sub_texts {
              sub_paragraph
              sub_title
            }
            text_content {
              title
              text
            }
            slideshow {
              first_item {
                image {
                  media_details {
                    sizes {
                      large {
                        source_url
                      }
                    }
                  }
                }
                info
                title
              }
              second_item {
                image {
                  media_details {
                    sizes {
                      large {
                        source_url
                      }
                    }
                  }
                }
                info
                title
              }
              third_item {
                image {
                  media_details {
                    sizes {
                      large {
                        source_url
                      }
                    }
                  }
                }
                info
                title
              }
            }
          }
        }
      }
    }
  }
`
