import * as React from 'react'
import styles from './styles.module.scss'
import { SlideshowAcf } from '../../models/Slideshow'

export type SlideshowProps = SlideshowAcf
//simplified component
const Slideshow: React.FC<SlideshowProps> = ({ slideshow }) => {
  const slidesArray = Object.values(slideshow)

  return (
    <>
      <div className={styles.slideshowContainer}>
        {slidesArray.map(
          (slide, index) =>
            slide && (
              <div className={styles.slide}>
                <span key={index}>
                  <h3>
                    <div>{slide?.title}</div>
                  </h3>
                  <p>{slide?.info}</p>
                  <img loading="lazy" src={slide?.image.media_details.sizes.large.source_url} alt="Matkasse" />
                </span>
              </div>
            )
        )}
      </div>
    </>
  )
}

export default Slideshow
